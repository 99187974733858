export default {
	init(Vue) {
		/**
		 * 使用API的KEY value填写API的value Vue.config.globalProperties VUE3挂载方法
		 */
		Vue.config.globalProperties.$api = {
			// 公共接口
			sendCode: { //短信
				url: 'api/sms/send',
				auth: false
			},
			getcaptcha: { //获取验证码
				url: 'pcapi/user/getcaptcha',
				auth: false
			},
			login: { //验证码登录pcapi/vip/viplist
				url: 'pcapi/user/smsyzm',
				auth: false
			},
			pwdLogin: { //密码登录
				url: 'pcapi/user/login',
				auth: false
			},
			forget: {
				url: 'pcapi/user/passup',
				auth: false
			},
			upload: { //上传图片
				url: 'system/uploadImage',
				auth: false
			},
			service: { //客服信息 系统
				url: 'api/user/config',
				auth: false
			},
			bannerList: { // 轮播
				url: 'api/user/banner',
				auth: false
			},
			logout: { //退出
				url: 'api/user/logout',
				auth: false
			},
			city: {
				url: 'api/store/city_pinyin',
				auth: false
			},
			pclist: { //扫码pc获取账号
				url: 'api/user/saoma',
				auth: true
			},
			pcLogin: { //扫码pc
				url: 'api/user/saoma_ip',
				auth: true
			},
			sdk: {
				url: 'api/qianming_hq',
				auth: false
			},
			appid: {
				url: 'api/huoqu_appid',
				auth: false
			},
			openid: {
				url: 'api/access_token_Wx',
				auth: false
			},


			//用户接口
			userInfo: { //用户信息
				url: 'api/user/center',
				auth: true
			},
			changeInfo: { //修改信息
				url: 'api/user/user_update',
				auth: true
			},
			changePwd: { //修改设置密码 支付密码
				url: 'pcapi/user/passup',
				auth: true
			},
			noticeDetail: { //消息/文章详情
				url: 'api/notice/ini',
				auth: false
			},
			article: { // 文章协议通知
				url: 'api/notice/list',
				auth: false
			},
			commentArt: { //评论
				url: 'api/notice/wzpinglun',
				auth: true
			},
			praise: { //点赞
				url: 'api/notice/dianzhan',
				auth: true
			},
			share: { //生成分享文章
				url: 'api/notice/shengcheng',
				auth: true
			},
			search: { //搜索文章
				url: 'pcapi/subject/soushuo',
				auth: false
			},
			reward: { //打赏
				url: 'api/notice/dashanwenzhang',
				auth: false
			},
			verify: { //上传身份证
				url: 'api/user/user_verify',
				auth: true
			},
			iplist: { //ip列表
				url: 'api/user/ip_list',
				auth: true
			},

			//vip
			order: { //购买记录
				url: 'pcapi/vip/vipbuylog',
				auth: true
			},
			vipList: { //vip列表
				url: 'pcapi/vip/viplist',
				auth: false
			},
			wapgoods: { //wapvip列表
				url: 'pcapi/vip/wapgoods',
				auth: false
			},
			wapbuy: { //wapvip购买
				url: 'pcapi/vip/wapbuy',
				auth: false
			},
			getwapticket: { // 获取ticket
				url: 'pcapi/vip/getwapticket',
				auth: false
			},
			getwapopenid: { // 获取openid
				url: 'pcapi/vip/getwapopenid',
				auth: false
			},
			wapfingVip: { // 查询是否存在当前产品的vip权限
				url: 'pcapi/vip/wapfingVip',
				auth: false
			},
			skillList: { //技巧列表
				url: 'api/notice/jiangjielx',
				auth: false
			},
			skillArticle: { // 技巧文章
				url: 'api/notice/jiangjiejx',
				auth: false,
			},
			buyVip: { //购买vip
				url: 'pcapi/vip/vipbuy',
				auth: true
			},

			//考试
			subjectCate: { //专项分类
				url: 'pcapi/subject/zhuanxian',
				auth: false
			},
			subjectList: { //分类列表
				url: 'pcapi/subject/fenlei',
				auth: true
			},
			secondarySubject: { //专项二级
				url: 'pcapi/subject/xiajifl',
				auth: true
			},
			audioList: { //语音分类
				url: 'pcapi/subject/yuyin',
				auth: true
			},
			iconCate: { //图标分类
				url: 'pcapi/subject/tubiaofenlei',
				auth: false
			},
			iconList: { //图标列表
				url: 'pcapi/subject/tubiaolist',
				auth: false
			},
			iconDetail: { //图标详情
				url: 'pcapi/subject/tubiaoxiangqing',
				auth: true
			},
			rank: { //排行榜
				url: 'pcapi/subject/paihangbang',
				auth: true
			},
			score: { //成绩单
				url: 'pcapi/subject/shijuanlist',
				auth: true
			},
			exam: { //试卷
				url: 'pcapi/subject/huoqutimu',
				auth: true
			},
			errorExam: { //错题试卷
				url: 'pcapi/subject/cuotisj',
				auth: true
			},
			subject: { //题目
				url: 'pcapi/subject/timuxiangqing',
				auth: true
			},
			examInfo: { //题目详情
				url: 'pcapi/subject/shijuanxiangqing'
			},
			collect: { //收藏题目
				url: 'pcapi/subject/shoucangtimu',
				auth: true
			},
			cancel: { //取消收藏错题
				url: 'pcapi/subject/shoucangdel',
				auth: true
			},
			contrastExam: { //对比题
				url: 'pcapi/subject/duibitisj',
				auth: true
			},
			postExam: { //答题
				url: 'pcapi/subject/dati',
				auth: true
			},
			sendExam: { //交卷
				url: 'pcapi/subject/jiaojuan',
				auth: true
			},
			scoreInfo: { //成绩分析
				url: 'pcapi/subject/shijuanxiangqing',
				auth: true
			},
			examRuler: { //考试规则
				url: 'pcapi/subject/huoquksgz',
				auth: true
			},

			//直播
			liveComment: { //直播评论
				url: 'pcapi/subject/zbpinglunlist',
				auth: true
			},
			liveList: { //直播列表
				url: 'pcapi/subject/zhibolist',
				auth: false
			},
			comment: { //直播评价
				url: 'pcapi/subject/zbpinglun',
				auth: true
			},

			//错题
			error: { //错题列表
				url: 'pcapi/subject/cuotisc',
				auth: true
			},
			errorInfo: { //错题详情
				url: 'pcapi/subject/cuotisc_ini',
				auth: true
			},

			//帮助中心
			feedback: { //反馈
				url: 'api/notice/feedback',
				auth: true
			},
			feedbackList: { //反馈列表
				url: 'api/notice/feedback_list',
				auth: true
			},
			feedbackDetail: { //反馈详情
				url: 'api/notice/feedback_ini',
				auth: true
			},
			refund: { //退款
				url: 'pcapi/vip/shenqingbuchang',
				auth: true
			},

			//提现
			addBank: { //添加银行卡
				url: 'pcapi/vip/yinhangka_add',
				auth: true
			},
			bankList: { //银行卡列表
				url: 'pcapi/vip/yinhangka_list',
				auth: true
			},
			deteleBankList: { //删除银行卡
				url: 'pcapi/vip/yinhangka_del',
				auth: true
			},
			cash: { //提现
				url: 'pcapi/vip/tixian',
				auth: true
			},
			cashList: { //提现记录
				url: 'pcapi/vip/tixianlog',
				auth: true
			},
			performance: { //业绩
				url: 'pcapi/vip/yeji_history',
				auth: true
			},

			//团队
			teamList: { //团队
				url: 'pcapi/vip/tuandui',
				auth: true
			},
			nextTeamList: { //下级团队
				url: 'pcapi/vip/xiajituandui',
				auth: true
			},
			bind: { //绑定关系
				url: 'pcapi/vip/bangdingguanxi',
				auth: true
			},

			//学员管理
			studentList: { //学员列表
				url: 'pcapi/teacher/xueyuan_list',
				auth: true
			},
			studentInfo: { //学员详情
				url: 'pcapi/teacher/xueyuan_ini',
				auth: true
			},
			changeStudent: { //修改学员信息
				url: 'pcapi/teacher/xueyuan_up',
				auth: true
			},
			addStudent: { //新增学员
				url: 'pcapi/teacher/xueyuan_add',
				auth: true,
			},
			deleteStudent: { //删除学员
				url: 'pcapi/teacher/xueyuan_del',
				auth: true,
			},

			//奖状
			admissionImg: { //入学通知书图片
				url: 'pcapi/teacher/ruxue',
				auth: true
			},
			admissionList: { //已生成的入学通知书
				url: 'pcapi/teacher/ruxue_list',
				auth: true
			},
			addAdmission: { //新增入学通知书
				url: 'pcapi/teacher/ruxue_add',
				auth: true
			},
			admissionInfo: { //入学通知详情
				url: 'pcapi/teacher/ruxue_ini',
				auth: true
			},
			deleteAdmission: { //删除入学
				url: 'pcapi/teacher/ruxue_del',
				auth: true
			},
			praiseList: { //表扬语
				url: 'pcapi/teacher/biaoyang',
				auth: true
			},
			awardList: { //奖状样式
				url: 'pcapi/teacher/jiangzhuang',
				auth: true
			},
			awardBg: {
				url: 'pcapi/teacher/jiangzhuangys_ini',
				auth: false
			},
			addAward: { //新增奖状
				url: 'pcapi/teacher/jiangzhuang_add',
				auth: true
			},
			awardInfo: { //奖状详情
				url: 'pcapi/teacher/jiangzhuang_ini',
				auth: false
			},
			awardLists: { //奖状列表
				url: 'pcapi/teacher/jiangzhuang_list',
				auth: true
			},
			deleteAward: { //删除奖状
				url: 'pcapi/teacher/jiangzhuang_del',
				auth: true
			},
			updateAward: { //修改奖状
				url: 'pcapi/teacher/jiangzhuang_up',
				auth: true
			},
			addBless: { //新增祝福
				url: 'pcapi/teacher/zhufu_add',
				auth: true
			},
			blessInfo: { //祝福详情
				url: 'pcapi/teacher/zhufu_ini',
				auth: false
			},
			blessList: { //祝福列表
				url: 'pcapi/teacher/zhufu_list',
				auth: true
			},
			deleteBless: { //删除祝福
				url: 'pcapi/teacher/zhufu_del',
				auth: true
			},
			musicList: { //音乐列表
				url: 'pcapi/teacher/music',
				auth: true
			},
			addGraduate: { //新增毕业证书
				url: 'pcapi/teacher/zhengshu_add',
				auth: true
			},
			graduateInfo: { //毕业证书详情
				url: 'pcapi/teacher/zhengshu_ini',
				auth: true
			},
			graduateList: { //毕业证书列表
				url: 'pcapi/teacher/zhengshu_list',
				auth: true
			},
			deteleGraduate: { //删除毕业证书
				url: 'pcapi/teacher/zhengshu_del',
				auth: true
			},
			appellation: { //考试称号
				url: 'pcapi/subject/kaoshichenghao',
				auth: true
			},

			//粉丝
			fansInfo: { //信息
				url: 'pcapi/teacher/fensi_tuandui',
				auth: true
			},
			fansList: { //粉丝列表
				url: 'pcapi/teacher/fensi_list',
				auth: true
			},
			fansData: { //粉丝资料
				url: 'pcapi/teacher/fensi_ini',
				auth: true
			},
			fansOrder: { //订单
				url: 'pcapi/teacher/fensi_order',
				auth: true
			},
			fansCash: { //提现记录
				url: 'pcapi/teacher/fensi_tixian',
				auth: true
			},
			fansMonth: { //月报
				url: 'pcapi/teacher/fensi_yuebao',
				auth: true
			},
			fansIncome: { //收益
				url: 'pcapi/teacher/fensi_shouyi',
				auth: true
			},
			changeRole: { //修改身份
				url: 'pcapi/teacher/level_up',
				auth: true
			},
			exVip: { //体验会员
				url: 'pcapi/teacher/vip_up',
				auth: true
			},
			unbind: { //解绑
				url: 'api/user/jiebang_wx',
				auth: true
			},
			cancelUser: { //注销
				url: 'api/user/cancellation',
				auth: true
			},
			progress: { //进度
				url: 'pcapi/subject/jindu',
				auth: true
			},
			pushinstall: { // 上报追踪参数
				url: 'qainstall/reports',
				auth: false
			}
		}
	},
}
